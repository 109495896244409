import {Auth} from 'aws-amplify';
import {Domain} from "src/config/BartLambdaConfig";
export const envInfo = {
    Stage: 'Prod',
    Region: 'us-east-1'
};


const betaConfigureAmp = {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-west-2:06a49ee7-ae5c-4e4e-9cd9-d05a36773aa0',

    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_WvV9dFBdR',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '1hf5ike2ifjom21i6ee61epqor',

    // OPTIONAL - Hosted UI configuration
    oauth: {
        domain: 'bart-userpool-beta.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn:'https://beta.bart-portal.fuse.amazon.dev',
        redirectSignOut:'https://beta.bart-portal.fuse.amazon.dev',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    federationTarget: 'COGNOTIO_USER_POOL',
    aws_appsync_graphqlEndpoint: 'https://geg6mgg7pzamnlagtbl2qt3c44.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: "us-west-2",
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-c5lrvovbgnckbor35ha6aj6zta',
}

const devoConfigureAmp = {
    ...betaConfigureAmp,
    oauth: {
        ...betaConfigureAmp.oauth,
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
    }
}

const prodFEConfigureAmp = {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-west-2:69d6e753-b949-47bb-9e2b-3a873cb91568',

    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_WbQUkZva3',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '11rmbqo1f6b95tsfidbvuonjk4',

    // OPTIONAL - Hosted UI configuration
    oauth: {
        domain: 'bart-userpool-prod.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn:'https://prod-jp.bart-portal.fuse.amazon.dev',
        redirectSignOut:'https://prod-jp.bart-portal.fuse.amazon.dev',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    federationTarget: 'COGNOTIO_USER_POOL',
    aws_appsync_graphqlEndpoint: 'https://ki6dcbwrgzhlxkkxwh7cxeoibi.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: "us-west-2",
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-ia56qw5d35gxzbttynyswgmdxe',
}

const prodUSConfigureAmp = {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-east-1:111bf117-be5b-4922-b978-fb321a8fa906',

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_L2etfKbPl',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '1dg4ctuha9mf4tm87794n2bak1',

    // OPTIONAL - Hosted UI configuration
    oauth: {
        domain: 'bart-userpool-prod.auth.us-east-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn:'https://prod-us.bart-portal.fuse.amazon.dev',
        redirectSignOut:'https://prod-us.bart-portal.fuse.amazon.dev',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    federationTarget: 'COGNOTIO_USER_POOL',
    aws_appsync_graphqlEndpoint: 'https://we3xjyxpbngdllsyl2vjkjghzq.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-tii6sqfcufdivkbrf3pk4eupwu',
}

const prodEUConfigureAmp = {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'eu-west-1:da46475e-16b1-4582-b69c-88da41302b0f',

    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-1_79IXJp9FF',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '6rommes7udqp1f30a5s9u1a5ls',

    // OPTIONAL - Hosted UI configuration
    oauth: {
        domain: 'bart-userpool-prod.auth.eu-west-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn:'https://prod-eu.bart-portal.fuse.amazon.dev',
        redirectSignOut:'https://prod-eu.bart-portal.fuse.amazon.dev',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    federationTarget: 'COGNOTIO_USER_POOL',
    aws_appsync_graphqlEndpoint: 'https://lxiwdqmepbb3hgffr7aanpfvp4.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: "eu-west-1",
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-tmgcn7vfjfcynfwy4midd4q7im',
}



export const configureAmp = ((envInfo) => {
    if (envInfo.Stage === 'Prod') {
        switch (envInfo.Region) {
            case 'us-west-2':
                return prodFEConfigureAmp;
            case 'us-east-1':
                return prodUSConfigureAmp;
            case 'eu-west-1':
                return prodEUConfigureAmp;
        }
    } else if (envInfo.Stage === 'development') {
        return devoConfigureAmp;
    }
    return betaConfigureAmp;
})(envInfo);

export const configureDomain:Domain = ((envInfo) => {
    if (envInfo.Stage === 'Prod') {
        switch (envInfo.Region) {
            case 'us-west-2':
                return 'prod-FE';
            case 'us-east-1':
                return 'prod-US';
            case 'eu-west-1':
                return 'prod-EU';
        }
    }
    return 'devo-FE';
})(envInfo);


// You can get the current config object
export const currentConfig = Auth.configure();
